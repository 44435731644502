.footer {
  width: 100%;
  height: 90vh;
  background-color: #2e3033;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .row_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }

  .copyright {
    width: 80%;
    // margin-left: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2em 0;
    text-align: center;
    border-top: 1px solid white;

    .footer_bottom_right {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .footer_btm_item {
        margin: 0 0.6em;

        a {
          color: white;
          transition: all 0.3s;

          &:hover {
            color: #fe5000;
          }
        }
      }
    }
  }

  .row1,
  .row2 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
  .c1,
  .c2 {
    margin: 5% 0;
    padding: 0 5%;
    // height: 100%;
    width: 50%;
  }

  .c1 {
    .logo {
      margin-top: 0.4em;
      font-size: 2em;
      display: flex;
      flex-direction: row;
      .com {
        color: #fe5000;
        font-size: 0.7em;
        padding-top: 0.5em;
      }
    }
  }
  .c2 {
    // border-left: 1px solid white;

    .title {
      width: max-content;
      padding: 0.3em 0;
      font-weight: 600;
      font-size: 2em;
      color: #fe5000;
      border-bottom: 1px solid #fe5000;
    }
    .content {
      margin: 3em 0;
      .cont-item {
        transition: all 0.3s;
        cursor: pointer;

        margin: 1.5em 0;
        &:hover {
          color: #fe5000;
        }
      }
    }
  }

  .f2 {
    .com {
      color: #fe5000;
      font-size: 0.7em;
      padding-top: 0.4em;
    }
  }
}

.socialMedia svg {
  color: white;
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
}

@media screen and (max-width: 1370px) {
  .service {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 500px) {
  .footer {
    flex-direction: column;
    height: auto;
    font-size: .8em;

    .row1,
    .row2 {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: row;
    }

    .row_cont {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }

    .c1,
    .c2 {
      // margin: 10% 0;
      width: 50%;
      font-size: 0.8em;
    }

    .c1 {
      .logo {
        font-size: 1.5em;
        display: flex;
        flex-direction: row;
        .com {
          color: #fe5000;
          font-size: 0.7em;
          padding-top: 0.5em;
        }
      }

      .click_me {
        margin-top: 4em;
        color: white;
        font-size: 1.2em;
      }
    }

    .f2 {
      .com {
        color: #fe5000;
        font-size: 0.7em;
        padding-top: 0.4em;
      }
    }

    .copyright {
      width: 90%;
      font-size: 0.7em;
      padding: 2em 0;
      text-align: center;
      border-top: 1px solid white;

      .footer_bottom_right {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .footer_btm_item {
          margin: 0 0.6em;

          a {
            color: white;
            transition: all 0.3s;

            &:hover {
              color: #fe5000;
            }
          }
        }
      }
    }
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
