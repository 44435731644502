.navbar {
  width: 100%;
  height: 12vh;
  background-color: black;
  display: flex;
  flex-direction: row;
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  font-size: 1em;

  .leftSide {
    width: 25%;
    height: 100%;
    display: flex;
    padding-left: 50px;
    .title {
      height: 100%;
      display: flex;
      padding: 1em;
      color: white;
      font-size: 2.2em;
    }
    .title_com {
      color: #fe5000;
      font-size: 0.6em;
      padding-top: 0.8em;
    }

    .h-sub-text {
      animation: animated-text 1.5s steps(30, end) 0.3s 1 normal both;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .center {
    display: flex;
    justify-content:center;
    // align-items: flex-end;
    height: 12vh;
    font-size: 1.2em;
    width: 75%;
    padding-right: 8em;

    .dropdown-content {
      transition: all 0.3s;
      display: none;
      position: absolute;
      right: -2.5em;
      top: 2em;
      background-color: #f9f9f9;
      min-width: 250px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    .dropdown-content a {
      font-family: sans-serif;
      margin: 0.3em;
      font-weight: 530;
      color: black;
      font-size: 1em;
      padding: 0.4em 0.6em;
      text-decoration: none;
      display: block;
      border-bottom: 2px solid white;
    }

    .dropdown-content a:hover {
      background-color: #f1f1f1;
    }
    .dropdown:hover {
      .dropdown-content {
        display: block;
      }
      .services_icon {
        transform: rotate(180deg);
        transition-duration: 0.3s;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      cursor: pointer;
      color: white;
      text-decoration: none;
      margin: 2em 1em;
      padding: 0.5em;
      font-size: 1em;
      transition: all 0.3s;

      &:hover {
        color: #fe5000;
        border-bottom: 2px solid #fe5000;
        background-color: black;
      }
    }
    .dropdown {
      margin: 2em 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      .services_icon {
        font-size: 1em;
      }
    }
  }

  .rightSide {
    height: 12vh;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      .contact_btn {
        color: white;
        text-align: center;
        background-color: #fe5000;
        padding: .6em 2em;
        font-size: 1.2em;
        border-radius: 20px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          // border: 1px solid white;
          scale: 1.05;
        }
      }
    }
  }

  .rightSide {
    justify-content: center;
  }
}

@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}


@media screen and (max-width: 1500px) {
  .navbar {
    height: 9vh;
    position: fixed;
    top: 0;
    .leftSide {
      font-size: .8em;
      width: 50%;
      height: 100%;
      display: flex;
      padding-left: 0px;

    }

  }
}

@media screen and (max-width: 500px) {
  .navbar {
    height: 9vh;
    position: fixed;
    top: 0;
    .leftSide {
      font-size: 0.6em;
      width: 70%;
      height: 100%;
      display: flex;
      padding-left: 0px;

    }
    .center {
      display: none;
    }
    .rightSide {
      display: none;
    }
  }
}
