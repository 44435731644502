@import "./styles/about";
@import "./styles/contact";
@import "./styles/footer";
@import "./styles/home";
@import "./styles/service";
@import "./styles/services";
@import "./styles/navbar";
@import "./styles/box";
@import "./styles/sidenav";
@import "./styles/faq";
@import "./styles/privacy";

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: "Quicksand", sans-serif;
  background-color: black;
}

html {
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 1870px) {
  .hamburger {
    display: none;
  }
  .cross {
    display: none;
  }
}

@media screen and (max-width: 1370px) {
  .App {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 1170px) {
  .App {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 500px) {

  .App {
    font-size: 1em;
  }
  .hamburger {
    display: block;
    color: #f95b12;

    z-index: 100;
    position: fixed;
    right: 20px;
    top: 10px;
  }
  .cross {
    display: block;
    color: #f95b12;
    z-index: 100;
    position: fixed;
    right: 20px;
    top: 10px;
    font-size: 2.5em !important;
  }
}
