.about {
  width: 100%;
  margin-top: 12vh;
  height: 88vh;
  display: flex;
  flex-direction: column;
}

.aboutTop {
  width: 100%;
  height: 70vh;
  color: white;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

  .abt {
    background-color: red;
  }
}

.aboutBottom {
  display: flex;
  color: white;
  flex-direction: column;
  height: 55%;
  align-items: center;
  margin-top: 50px;

  .click_me {
    margin-top: 3em;
    // text-align: center;

    color: white;
    font-size: 1.5em;
  }


  .faq {
    width: 70%;
    font-size: 3em;
    color: white;
    margin-top: 1em;
    background-color: #fe5000;
    border: 1px solid white;
    text-align: center;
    border-radius: 20px;
    font-weight: 400;
    padding: .3em .8em;

    transition: all .3s;

    &:hover{
      scale: 1.05;
    }
  }
}

.aboutBottom p {
  width: 1200px;
}

.about .aboutBottom h1 {
  font-weight: 400;
  font-size: 70px;
  color: black;
  height: 30px;
  display: flex;
  justify-content: center;
  transform: translateY(-40px);
}

@media screen and (max-width: 500px) {
  .hamburger {
    display: block;
  }
  .cross {
    display: block;
    font-size: 2.2em !important;
  }
}
