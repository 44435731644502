.privacy {
  min-height: 100vh;
  margin-top: 12vh;
  background-color: white;
  color: black;
  padding: 3em 0;
  font-size: 1.3em;

  .content {
    width: 80%;
    margin-left: 10%;

    .h1 {
      color: #fe5000;
    }
  }

  p {
    opacity: 0.6;
  }
}

@media screen and (max-width: 500px) {
  .privacy {
    font-size: 1em;
    .content {
      .h1 {
        text-align: center !important;
      }
    }
  }
}
