.FAQ {
  transition: all 0.3s;
  .faq_cont {
    width: 80%;
    margin-left: 10%;
    margin-top: 20vh;
    margin-bottom: 20vh;

    height: auto;
    background-color: black;

    .faq_item {
      margin-top: 4em;
      .title {
        display: flex;
        cursor: pointer;
        // justify-content: center;
        align-items: center;
        background-color: lightgray;
        padding: 0.8em 0.6em;
        color: #fe5000;
        font-size: 2em;
        font-weight: 500;

        .icon {
          font-size: 1.5em;
          margin-right: 0.5em;
        }
      }
      .content {
        padding: 1.5em 1em;
        font-size: 1.3em;
        background-color: white;
      }
    }
  }
}


@media screen and (max-width: 500px) {
  .FAQ {
    .faq_cont {
      width: 90%;
      margin-left: 5%;
      margin-top: 20vh;
      margin-bottom: 20vh;
  
      .faq_item {
        margin-top: 3em;
        .title {
          padding: 0.6em 0.4em;
          color: #fe5000;
          font-size: 1.5em;
          font-weight: 600;
  
          .icon {
            font-size: 1em;
            margin-right: 0.5em;
          }
        }
        .content {
          padding: 1.5em 1em;
          font-size: 1.3em;
          background-color: white;
        }
      }
    }
  }
  
}