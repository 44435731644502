.box_cont {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #43464b;

  display: flex;
  flex-direction: column;
  // padding: 2em 0;

  .box {
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 4em;
    background-color: #43464b;

    .box_right {
      background-color: black;
      height: 100%;
      width: 60%;

      color: white;
      .box_title {
        font-size: 4em;
      }
    }
    .box_left {
      width: 40%;
      height: 100%;
    }
  }

  .box_odd {
    .box_left {
      .box_img {
        margin-top: 3em;
        margin-left: -8em;
        height: 70%;
        width: 100%;
        background-color: orange;
      }
    }
    .box_right {
      padding-right: 10em;

      .box_title {
        font-weight: 600;
        margin: 1em 1em;
        color: #f95b12;
      }

      .box_content {
        font-size: 1.5em;
        margin-left: 2em;
        li {
          margin: 0.8em 0;
        }
      }
    }
  }

  .box_even {
    .box_left {
      z-index: 40;
      .box_img {
        margin-top: 3em;
        margin-left: 8em;
        height: 70%;
        background-color: orange;
        width: 100%;
      }
    }

    .box_right {
      padding-right: 3em;

      .box_title {
        font-size: 4em;
        font-weight: 600;
        margin-top: 1em;
        margin-left: 3em;

        color: #f95b12;
      }

      .box_content {
        margin-top: 3em;
        padding-right: 2em;
        font-size: 1.5em;
        margin-left: 8em;
        li {
          margin: 0.8em 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1370px) {
  .box {
    font-size: 0.9em;
  }

  .box_odd {
    .box_left {
      .box_img {
        margin-top: 3em;
        margin-left: -8em;
        height: 70%;
        width: 100%;
        background-color: orange;
      }
    }
    .box_right {
      padding-right: 10em;

      .box_title {
        font-weight: 600;
        margin: 1em 1em;
        color: #f95b12;
      }

      .box_content {
        font-size: 1.5em;
        margin-left: 2em;
        li {
          margin: 0.8em 0;
        }
      }
    }
  }

  .box_even {
    .box_left {
      z-index: 40;
      .box_img {
        margin-top: 3em;
        margin-left: 8em;
        height: 70%;
        width: 100%;
        background-color: orange;
      }
    }

    .box_right {
      // padding-right: 3em;

      .box_title {
        font-size: 4em;
        font-weight: 600;
        margin-top: 1em;
        margin-left: 3em;

        color: #f95b12;
      }

      .box_content {
        margin-top: 3em;
        padding-right: 2em;
        font-size: 1.5em;
        margin-left: 8em;
        li {
          margin: 0.8em 0;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .box_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 1em 0;

    .box {
      flex-direction: column;
      height: auto;
      width: 100%;
      font-size: 0.5em;
      background-color: #43464b;
      ul {
        width: 80%;
      }

      .box_left {
        width: 100%;
        height: 30vh;

        background-color: #43464b;
      }
      .box_right {
        width: 100%;
        background-color: #43464b;
      }
    }

    .box_odd {
      .box_left {
        .box_img {
          // position: absolute;
          left: 0;
          margin-top: -3em;
          margin-left: 20%;
          height: 80%;
          width: 80%;
          background-color: orange;
        }
      }
      .box_right {
        padding: 6em 0;

        .box_title {
          font-weight: 600;
          margin: 0 0.7em;
          color: #f95b12;
        }

        .box_content {
          font-size: 1.5em;
          margin-left: 0em;
          li {
            margin: 0.4em 0;
          }
        }
      }
    }

    .box_even {
      .box_left {
        z-index: 40;

        .box_img {
          margin-top: 20%;
          margin-right: 20%;
          margin-left: 0;
          height: 80%;
          width: 80%;
          background-color: orange;
        }
      }

      .box_right {
        padding: 7em 4em 2em;
        // padding-left: 10%;
        // padding-top: 2em;

        .box_title {
          font-size: 3em;
          font-weight: 600;
          margin-top: 0em;
          margin-left: 0em;
          // padding-left: 10%;

          margin: 0 0.7em;

          color: #f95b12;
        }

        .box_content {
          margin-top: 3em;
          margin-left: 0;
          font-size: 1.5em;
          margin-left: 0%;

          ul {
            li {
              margin: 0.8em 0;
            }
          }
        }
      }
    }
  }
}
