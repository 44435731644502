.contact {
  width: 100%;
  font-size: 2em;
  margin-top: 12vh;
  min-height: 88vh;
  height: auto;
  display: flex;
  color: #fe5000;
}

.contact .leftSide {
  height: 100%;
  flex: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
}

.contact .rightSide {
  height: 100%;
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .rightSide h2 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 10;
  font-size: 50px;
  margin-left: 30px;
}

form {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 30px;
  .name_cont {
    display: flex;
    flex-direction: row;
  }
  .fn,
  .ln {
    width: 50%;
  }
}

.name_cont {
  input {
    width: 80%;
  }
}

form input {
  height: 50px;
  width: 90%;

  border: none;
  border: 1px solid #121619;
  color: black;
  opacity: 0.9;
  padding: 0 1em;
  border-radius: 10px;
  font-size: 0.6em;
  margin: 0.5em 0;
  background-color: lightcyan;
}

form textarea {
  margin-top: 15px;
  height: 70px;
  width: 92%;
  border: none;
  border: 1px solid #121619;
  background-color: lightcyan;
  // color: black;\
  opacity: 0.9;
  padding: 1em;
  font-size: 0.6em;
  border-radius: 10px;
}
input::placeholder,
textarea::placeholder {
  color: gray;
  font-family: Arial, Helvetica, sans-serif;
}
form input:focus,
textarea:focus {
  outline: none;
}

form label {
  margin-top: 15px;

  color: white;

  font-weight: bold;
}

form button {
  margin-top: 40px;
  width: 140px;
  height: 40px;
  border: none;
  color: whitesmoke;
  font-size: 15px;
  cursor: pointer;
  background-color: #fe5000;
  border-radius: 10px;
}



@media screen and (max-width: 500px) {
  .contact {
    flex-direction: column;
    font-size: 0.7em;
    h2 {
      font-size: 0.3em;
      margin: 0.2em 0;
    }

    .contact-form{
      padding: 4em;
    }
  }
  .contact .leftSide {
    height: 0;
    flex: 0;
  }

  .name_cont {
    input {
      width: 80%;
    }
  }

  form input {
    font-size: 1em;
  }

  form textarea {
    font-size: 1em;
  }

  form button {
    // position: absolute;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}
