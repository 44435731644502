.service {
  min-height: 100vh;

  width: 100%;

  .parallax {
    min-height: 65vh;
    background-attachment: fixed;
    background-position: 0 10%;
    background-repeat: no-repeat;
    background-size: 100% 70%;
    .title_cont {
      background-color: black;
      opacity: 0.8;
      // margin-top: 20vh
      position: absolute;
      top: 20vh;
      // height: 30vh;
      height: auto;

      padding: 3em;
      width: 40%;

      .service_page_title {
        color: #fe5000;
        font-size: 4em;
        font-weight: 800;
      }
      .service_page_content {
        color: white;
        margin-top: 1.3em;
        font-size: 1.5em;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 1370px) {
  .service {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 500px) {
  .service {
    overflow-x: hidden;

    .parallax {
      min-height: 55vh;

      height: 35vh;
      background-attachment: fixed;
      background-position: 50% 20%;
      background-repeat: no-repeat;
      background-size: 100% 35%;

      .title_cont {
        font-size: 0.6em;
        width: 70%;
        top: 15vh;
      }
    }
  }
}
