.homepage {
  margin-top: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home {
  width: 100%;
  height: 70vh;
  background-position: 25% 75%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

  .home_left {
    margin-top: 4em;
    margin-left: 2em;
    font-size: 1.5em;
    font-weight: 300;
    color: white;
    padding: 0.2em 1em;
    width: 60%;
    font-family: sans-serif;
    .About_us {
      font-size: 3em;
      color: #fe5000;
      margin-bottom: 0.4em;
    }
  }
  .home_right {
    position: absolute;
    top: 17em;
    right: 3em;
    font-size: 1.2em;
    color: white;
    padding: 0.2em 1em;
    font-family: sans-serif;
    .tagline {
      font-size: 1em;
      color: #f95b12;
      padding: 0.2em 1em;
    }

    .values {
      display: flex;
      flex-direction: row;
      .value_cont {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: auto;
        background-color: red;
      }
    }
  }
}

#services {
  padding-top: 14vh;
  height: auto;
  width: 90%;
  background-color: black;
  display: flex;
  flex-direction: column;

  .sr2 {
    width: 100%;
    height: 40vh;

    display: flex;
    align-items: center;
    justify-content: center;

    .c123 {
      background-color: white;
      color: black;
      width: 30%;

      height: 35vh;
      margin: 1em 2em;
      .services_cont {
        padding-right: 3em;
      }
      // margin: 2em;
      background-color: black;
      cursor: pointer;
      transition: all 0.3s;
      .services_title {
        margin: 0.1em -1em;
        font-size: 4em;
        font-weight: 600;
        color: white;
        color: #fe5000;
      }
      .services_cont {
        margin: 2em -2em;
        font-size: 1.3em;

        color: white;
        color: grey;
      }
    }
    .c1 {
      background-color: white;
      color: black;
      width: 30%;
      height: 35vh;

      margin: 1em 2em;

      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        scale: 1.01;
        background-color: #fe5000;
        color: white;
        .second_half {
          .cat_title {
            border-bottom: 2px solid white;
          }
        }
      }

      .first_half {
        height: 40%;
        width: 100%;
        background-color: white;

        display: flex;
        // justify-content: center;
        align-items: center;

        img {
          position: absolute;
          width: 150px;
        }

        .docker {
          width: 120px;
          margin-left: 1em;
        }

        .analytics {
          width: 120px;
        }
        .web_dev {
          width: 150px;
          margin-left: 1em;
        }
        .daas {
          width: 100px;
          margin-left: 1em;
        }
        .icon {
          font-size: 6em;
          margin-left: 0.2em;
          color: #f95b12;
        }
      }
      .second_half {
        height: 60%;
        width: 100%;
        // margin: 0.6em 1em;
        .cat_title {
          font-size: 2em;
          font-weight: 800;
          padding: 0.5em 0.8em;
          // border-bottom: 2px solid red;
          width: max-content;
        }
        .cat_content {
          font-size: 1em;
          margin: 0.4em 1.5em;
          // font-size: 1.1em;
          font-weight: 500;

          ul {
            margin: 0;
            padding: 0 3em;
          }
        }
      }
    }
  }

  .menuTitle {
    text-align: center;
    width: fit-content;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 3em;
    margin: 0.2em;
    padding: 0.4em 0.5em;
    border-bottom: 2px solid black;
  }
}

.tangible_results {
  margin: 6em 0;
  // background-color: #43464B;
  height: 600px;
  .title {
    font-size: 3em;
    margin: 0.5em;
    font-weight: 600;
    color: #fe5000;
  }
  .results_cont {
    display: flex;
    flex-direction: row;
    .box1 {
      transition: all 0.3s;
      background-color: white;
      height: 400px;
      width: 25%;
      margin: 4% 2%;
      .first {
        height: 35%;
        .icon {
          font-size: 5em;
          color: #f95b12;
          margin: 0.5em 0.2em;
        }
      }
      .second {
        padding: 0.7em;
        height: 65%;
        .box_title {
          font-size: 1.3em;
          margin-left: 0.5em;
          font-weight: 600;

          color: #f95b12;
        }
        .content {
          padding: 0.7em;
        }
      }

      &:hover {
        scale: 1.01;
        box-shadow: inset 0px 0px 30px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 2px 30px #f95b12;
      }
    }
  }
}

.explore {
  margin-bottom: 4em;
  color: white;
  width: 80%;
  margin-left: 10%;
  height: 100px;
  display: flex;
  flex-direction: row;
  .left {
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2em;
  }

  .right {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      text-decoration: none;

      .button {
        text-decoration: none;
        transition: all 0.3s;
        color: white;
        font-size: 1.2em;
        cursor: pointer;
        padding: 1em 3em;
        background-color: #fe5000;
        border-radius: 20px;
        &:hover {
          scale: 1.05;
        }
      }
    }
  }
}

.headerContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  .home_right {
    width: 50%;
    height: 100%;
    // scale: .8;

    img {
      position: absolute;
      width: 70px;
      height: 70px;
    }

    .icons {
      scale: 0.8;
      animation-name: floating;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;

      transition: all 0.3s;
      padding: 0.2em;
      box-shadow: 0px 3px 6px #037fff;
      border-radius: 100%;
      &:hover {
        scale: 0.9;
        cursor: pointer;
        box-shadow: 0px 5px 10px #037fff;
      }
    }

    .img1 {
      right: 10%;
      top: 25%;
    }
    .img2 {
      right: 25%;
      top: 28%;
      animation-delay: 1s;
    }
    .img3 {
      right: 35%;
      top: 48%;
      animation-delay: 2s;
    }
    .img4 {
      right: 6%;
      top: 50%;
      animation-delay: 3s;
    }
    .img5 {
      right: 14%;
      top: 70%;
      animation-delay: 1s;
    }

    .img6 {
      right: 26%;
      top: 83%;
      animation-delay: 2s;
    }

    .img7 {
      right: 38%;
      top: 72%;
      animation-delay: 3s;
    }
    .img8 {
      right: 18%;
      top: 42%;
      animation-delay: 3s;
    }

    .img9 {
      right: 28%;
      top: 62%;
      animation-delay: 1s;
    }
  }
}

.headerContainer h1 {
  justify-content: center;
  align-items: center;
  font-size: 90px;
  height: 60px;
  font-weight: 50;
  color: black;
}

.headerContainer p {
  padding: 0;
  font-size: 40px;
  font-weight: lighter;
  color: black;
}

.headerContainer button {
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  background-color: #121619;
  height: 50px;
  width: 180px;
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.headerContainer button:hover {
  background-color: #07090a;
  cursor: pointer;
  transition: 0.3s ease-in;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
}

@media screen and (max-width: 1550px) {
  .home {
    .home_left {
      margin-top: 6em;
      font-size: 1.2em;
    }
  }

  #services {
    padding-top: 14vh;

    .sr2 {
      .c123 {
        width: 30%;
        height: 35vh;
        margin: 1em 2em;
        .services_cont {
          padding-right: 3em;
        }
        .services_title {
          margin: 0.1em -1em;
          font-size: 4em;
          font-weight: 600;
          color: white;
          color: #fe5000;
        }
        .services_cont {
          margin: 2em -2em;
          font-size: 1.3em;

          color: white;
          color: grey;
        }
      }
      .c1 {
        background-color: white;
        color: black;
        width: 30%;
        height: 35vh;

        margin: 1em 2em;

        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          scale: 1.01;
          background-color: #fe5000;
          color: white;
          .second_half {
            .cat_title {
              border-bottom: 2px solid white;
            }
          }
        }

        .first_half {
          height: 40%;
          width: 100%;
          background-color: white;

          display: flex;
          // justify-content: center;
          align-items: center;

          img {
            position: absolute;
            width: 150px;
          }

          .docker {
            width: 120px;
            margin-left: 1em;
          }

          .analytics {
            width: 120px;
          }
          .web_dev {
            width: 150px;
            margin-left: 1em;
          }
          .daas {
            width: 100px;
            margin-left: 1em;
          }
          .icon {
            font-size: 6em;
            margin-left: 0.2em;
            color: #f95b12;
          }
        }
        .second_half {
          height: 60%;
          width: 100%;
          // margin: 0.6em 1em;
          .cat_title {
            font-size: 1.5em;
            font-weight: 800;
            padding: 0.5em 0.8em;
            // border-bottom: 2px solid red;
            width: max-content;
          }
          .cat_content {
            font-size: 1em;
            margin: 0.4em 1.5em;
            // font-size: 1.1em;
            font-weight: 500;

            ul {
              margin: 0;
              padding: 0 3em;
            }
          }
        }
      }
    }

    .menuTitle {
      text-align: center;
      width: fit-content;
      font-family: "M PLUS Rounded 1c", sans-serif;
      font-size: 3em;
      margin: 0.2em;
      padding: 0.4em 0.5em;
      border-bottom: 2px solid black;
    }
  }
}

@media screen and (max-width: 1320px) {
  #services {
    .sr2 {
      .c123 {
        .services_title {
          margin: 0.1em -1em;
          font-size: 3em;
          font-weight: 600;
          color: white;
          color: #fe5000;
        }
        .services_cont {
          margin: 2em -2em;
          font-size: 1.3em;

          color: white;
          color: grey;
        }
      }
      .c1 {
        background-color: white;
        color: black;
        width: 30%;
        height: 35vh;

        margin: 1em 2em;

        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          scale: 1.01;
          background-color: #fe5000;
          color: white;
          .second_half {
            .cat_title {
              border-bottom: 2px solid white;
            }
          }
        }

        .first_half {
          height: 40%;
          width: 100%;
          background-color: white;

          display: flex;
          // justify-content: center;
          align-items: center;

          img {
            position: absolute;
            width: 150px;
          }

          .docker {
            width: 120px;
            margin-left: 1em;
          }

          .analytics {
            width: 120px;
          }
          .web_dev {
            width: 150px;
            margin-left: 1em;
          }
          .daas {
            width: 100px;
            margin-left: 1em;
          }
          .icon {
            font-size: 6em;
            margin-left: 0.2em;
            color: #f95b12;
          }
        }
        .second_half {
          height: 60%;
          width: 100%;
          // margin: 0.6em 1em;
          .cat_title {
            font-size: 1.1em;
            font-weight: 800;
            padding: 0.5em 0.8em;
            // border-bottom: 2px solid red;
            width: max-content;
          }
          .cat_content {
            font-size: 0.8em;
            margin: 0.4em 1.5em;
            // font-size: 1.1em;
            font-weight: 500;

            ul {
              margin: 0;
              padding: 0 3em;
            }
          }
        }
      }
    }

    .menuTitle {
      text-align: center;
      width: fit-content;
      font-family: "M PLUS Rounded 1c", sans-serif;
      font-size: 3em;
      margin: 0.2em;
      padding: 0.4em 0.5em;
      border-bottom: 2px solid black;
    }
  }

  .tangible_results {
    .title {
      font-size: 2em;
    }
    .results_cont {
      .box1 {
        .second {
          .box_title {
            font-size: 1em;
          }
        }
      }
    }
  }

  .explore {
    margin-left: 5%;
    width: 90%;
    .left {
      font-size: 1.8em;
    }
  }
}

@media screen and (max-width: 500px) {
  .home {
    .home_left {
      font-size: 0.8em;
      width: 80%;
    }
  }

  .tangible_results {
    height: auto;
    .title {
      font-size: 1.5em;
      margin-left: 1.5em;
    }
    .results_cont {
      flex-direction: column;
      .box1 {
        background-color: white;
        height: 350px;
        width: 80%;
        margin: 4% 2%;
        margin-left: 10%;
        .first {
          height: 30%;
          .icon {
            font-size: 3.5em;
            color: #f95b12;
            margin: 0.5em 0.4em;
          }
        }
        .second {
          padding: 0.7em;
          height: 70%;
          .box_title {
            font-size: 1.3em;
          }
          .content {
            padding: 0.5em 0.7em;
          }
        }
      }
    }
  }

  #services {
    flex-direction: column;
    .sr2 {
      flex-direction: column;
      height: auto;

      .c123 {
        font-size: 1em;
        margin: 3em 0;
        width: 80%;
        height: 150px;

        .services_title {
          margin: 0;
          font-size: 2em;
          font-weight: 600;
        }
        .services_cont {
          padding-right: 0;
          margin: 1em 0em;
          color: grey;
          font-size: 1.1em;
        }
      }
      .c1 {
        height: 300px;

        width: 85%;
        font-size: 1em;

        .first_half {
          width: 100%;
          font-size: 0.7em;

          img {
            position: absolute;
            width: 100px;
            margin-left: -1em;
          }
          .docker {
            width: 70px;
            margin-left: 2em;
          }

          .analytics {
            width: 90px;
            margin-left: 0.6em;
          }

          .web_dev {
            width: 90px;
            margin-left: 1.4em;
          }
          .daas {
            width: 70px;
            margin-left: 1.6em;
          }

          .icon {
            margin-left: 0.4em;
          }
        }
        .second_half {
          .cat_title {
            font-size: 1.2em;
          }
        }
      }
    }
  }

  .explore {
    margin-bottom: 4em;
    color: white;
    width: 80%;
    margin-left: 10%;
    height: auto;
    display: flex;
    flex-direction: column;
    .left {
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.3em;
    }

    .right {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        text-decoration: none;

        .button {
          text-decoration: none;
          transition: all 0.3s;
          color: white;
          font-size: 1.2em;
          cursor: pointer;
          padding: 1em 3em;
          background-color: #fe5000;
          border-radius: 20px;
          margin-top: 3em;
          &:hover {
            scale: 1.05;
          }
        }
      }
    }
  }
}
