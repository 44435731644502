#mySidenav {
  height: 100vh;
  width: 150px;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: black;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 10em;

  display: flex;
  flex-direction: column;
  padding-left: 2em;
  a {
    padding: 1em;
    // background-color: white;
    color: white;
  }
}

// .sidenav a {
//   padding: 8px 8px 8px 32px;
//   text-decoration: none;
//   font-size: 25px;
//   color: #818181;
//   display: block;
//   transition: 0.3s;
// }

// .sidenav a:hover {
//   color: #f1f1f1;
// }

// .sidenav .closebtn {
//   position: absolute;
//   top: 0;
//   right: 25px;
//   font-size: 36px;
//   margin-left: 50px;
// }

.nav-toggle {
  margin-left: -200px;
}
