.menu {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 4em;
}


.menuList {
  width: 90vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

/* MENU ITEM STYLING */

.menuItem {
  border-radius: 15px;
  cursor: pointer;
  width: 350px;
  height: 400px;
  margin: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);

  background-color: white;

  .menu_img_cont {
    border-bottom: 1px solid black;
    height: 70%;
    border-radius: 15px 15px 0 0;
  }

  .menu_text_cont {
    background-color: #037fff;
    height: 30%;
    border-radius: 0 0 15px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: white;
  }

  .image-data {
    position: relative;
    box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 16px 17px -5px #000000;
    margin-bottom: 2em;
    &::before {
      content: "";
      position: absolute;
      top: 12px;
      left: 15px;
      height: calc(100% - 30px);
      width: calc(100% - 30px);
      background-color: rgba(255, 255, 255, 0.925);
      transform-origin: center;
      transform: scale(0);
      transition: all 0.4s ease-in-out;
    }
    &:hover::before {
      transform: scale(1);
    }
    .hover-items {
      list-style: none;
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 1rem 2rem;
      visibility: hidden;
      li {
        a {
          padding: 1rem 2em 1em 1em;
          text-decoration: none;
          font-family: inherit;
          border-radius: 10px;
          background-color: #037fff;
          color: white;
          font-size: 0.8em;
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-right: 2rem;
          }
        }
      }
    }

    &:hover {
      .hover-items {
        transition: all 0.5s ease-in-out 0.2s;
        visibility: visible;
        top: 50%;
      }
    }
  }
}
// .menuItem:hover {
//   box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
//   transition: 0.2s ease-in;
//   scale: 1.05;
//   cursor: pointer;
// }

.menuItem h1,
.menuItem p {
  margin-left: 20px;
}

@media only screen and (max-width: 1300px) {
  .menuList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .menuList {
    grid-template-columns: 1fr;
  }
}
